<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title=""
    width="50%"
  >
    <el-table
      ref="multipleTableRef"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :height="contentStyleObj"
      border
    >
      <el-table-column label="类似群" width="70">
        <template #default="scope">
          <span style="cursor: pointer;color: #3c6ff2;" @click="openThreeLevel(scope.row.id)">{{ scope.row.leiBie }}</span>
        </template>
      </el-table-column>
      <el-table-column property="shuoMing" label="说明" />
    </el-table>
    <!-- <template #footer>
      <span class="dialog-footer">
        <el-button @click="dayinCustomStatus = false" size="small">取 消</el-button>
        <el-button type="primary" @click="printingCustom" size="small">确 定</el-button>
      </span>
    </template> -->
  </el-dialog>
  <threeLevel ref="threeLevel" @success="choiceProduct"></threeLevel>

</template>

<script>
import { productsList } from "@/api/crm"
import threeLevel from "./threeLevel.vue"

export default {
  name:'twoLevel',
  components:{
    threeLevel 
  },
  data(){
    return{
      dialogVisible:false,
      tableData:[]
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(350)
  },
  methods:{
    init(list){
      this.tableData = list
      this.dialogVisible = true
    },
    openThreeLevel(id){
      productsList({pid:id}).then(res => {
        if(res.data.data.list){
          this.$refs.threeLevel.init(res.data.data.list)
        }
      })
    },
    choiceProduct(e){
      this.$emit('success',e)
      this.dialogVisible = false
    }
  }
}
</script>

<style>

</style>