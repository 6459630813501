<template>

  <div class="bigimg">
    <img style="width: 180px; border: 1px solid #eee" :src="img" alt="" />
    <el-button type="primary" size="mini"
      ><input
        @change="uploadimg($event)"
        class="inputfl"
        type="file"
        name="upFile"
        id=""
      />上传图片</el-button
    >
  </div>

</template>

<script>
// import {
//   uploadImg,
// } from "@/api/crm";
import axios from "axios";
export default {
  data() {
    return {
      img: "http://p.funcodeit.com/admin/home/add_pic.jpg",
    };
  },
  props:{
    img2:""
  },
  created() {
    if(this.img2){
      this.img = this.img2
    }
  },
  methods: {
    uploadimg(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files[0]; // 提交的图片
        this.addSub();
      }
    },
    addSub() {
      const files = this.files;
      const isLt1M = files.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({
          message: "请不要上传大于1M的文件。",
          type: "warning",
        });
        return;
      }
      const param = new FormData(); // 创建form对象
      if (files !== "") {
        param.append("upFile", files, files.name); // 单个图片 ，多个用循环 append 添加
      } else {
        this.$message.error("请添加图片");
      }
      console.log("/////",param);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          const imgs = res.data.data;
          this.img = "https://file.listensoft.net" + imgs.url;
          this.$emit("uploadimgzj", this.img);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style>
.bigimg {
  margin-left: 70px;
}
.bigimg button {
  transform: translateY(-10px);
  margin-left: 20px;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
</style>
