<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title=""
    width="50%"
  >
    <el-table
      ref="multipleTableRef"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :height="contentStyleObj"
      border
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="编号" width="100">
        <template #default="scope">{{ scope.row.leiBie }}</template>
      </el-table-column>
      <el-table-column property="shuoMing" label="商品名称"  />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleProduct" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data(){
    return{
      dialogVisible:false,
      tableData:[],
      multipleSelection:[]
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(350)
  },
  methods:{
    init(list){
      this.tableData = list
      this.dialogVisible = true
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    handleProduct(){
      this.$emit('success',this.multipleSelection)
      this.dialogVisible = false

    }
  }
}
</script>

<style>

</style>