<template>

  <div>
    <!-- <el-button type="primary" size="mini"
      ><input
        @change="uploadimg($event)"
        class="inputfl"
        type="file"
        name="upFile"
        id=""
      />上传图片</el-button
    > -->
    <el-input style="width:50%;margin-right:10px" v-model="path"></el-input>
    <input size="small" ref="excel-upload-input" class="excel-upload-input" style="display:none" type="file" accept=".xlsx, .xls, .xml" @change="handleClick">
    <el-button type="primary" @click="handleUpload()" size="small">请选择文件</el-button>
  </div>

</template>

<script>
// import {
//   uploadImg,
// } from "@/api/crm";
import axios from "axios";
export default {
  data() {
    return {
      rawFile: '',
      path: '',
      files:''
    };
  },
  methods: {
    handleClick(e) {
      this.files = e.target.files;
      const rawFile = this.files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile
      this.addSub();
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadimg(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files[0]; // 提交的图片
        this.addSub();
      }
    },
    addSub() {
      const files = this.rawFile;
      const param = new FormData(); // 创建form对象
      if (files !== "") {
        param.append("upFile", files); // 单个图片 ，多个用循环 append 添加
      } else {
        this.$message.error("请添加图片");
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("https://public.listensoft.net/api/uploadImg", param, config)
        .then((res) => {
          const imgs = res.data.data;
          this.img = "https://file.listensoft.net" + imgs.url;
          this.$emit("uploadimgzj", this.img);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>


<style>
.bigimg {
  margin-left: 70px;
}
.bigimg button {
  transform: translateY(-10px);
  margin-left: 20px;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
</style>
